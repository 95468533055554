import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "./Context";
import "./App.css";
import Axios from "axios";
import Header from "./Header";

function NewTech() {
  const [categoryName, setCategoryName] = useState([]);

  const token = localStorage.getItem("token");
  const { login } = useContext(LoginContext);
  const handleTech = (e) => {
    Axios.post(
      "https://blogserver.friendlyapk.com/api/createcategory",{
        categoryName: categoryName,
        token:token
      }
    ).then(() => {
      alert("Successfully Inserted");
    });
    e.preventDefault();
    e.target.reset();
  };
  if (login) {
    return (
      <main>
        <Header />
        <h2 className="h2">Create A New Category</h2>
        <form action="../admins/newtech" onSubmit={handleTech} className="InsertFormGame">
          <label htmlFor="category">Category</label>
          <input
            type="text"
            name="category"
            id=""
            onChange={(e) => {
              setCategoryName(e.target.value);
            }}
          />
          <button type="submit">Submit</button>
        </form>
      </main>
    );
  } else {
    return <Navigate to="../" />;
  }
}
export default NewTech;
