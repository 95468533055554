import React from "react";
import "./App.css";
import { Link } from "react-router-dom";

function Header() {
    return(
        <header className="adminaside">
        <Link to="../"> <img src="https://i.ibb.co/brQQ9qP/sign.png" alt="Sign in out" /> </Link>
       <Link to="../createblog" ><img src="https://i.ibb.co/DRsspvv/plus.png" alt="Add Game" /></Link>
       <Link to="../editblog" ><img src="https://i.ibb.co/282RVxT/edit.png" alt="Edit Game" /></Link>
       <Link to="../deleteblog" ><img src="https://i.ibb.co/WvBmytm/trash-can.png" alt="Delete Game" /></Link>
       <Link to="../createcategory" ><img src="https://i.ibb.co/DRsspvv/plus.png" alt="Add Game" /></Link>
       <Link to="../deletecategory" ><img src="https://i.ibb.co/WvBmytm/trash-can.png" alt="Delete Game" /></Link>

      </header>
    )
}
export default Header;