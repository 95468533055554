import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "./Context";
import "./App.css";
import Axios from "axios";
import Header from "./Header";

function DeleteTech() {
    const [categoryList, setCategoryList] = useState([]);
    const { login } = useContext(LoginContext);

  useEffect(() => {
    Axios.get("https://blogserver.friendlyapk.com/api/getcategory").then((res) => {
      setCategoryList(res.data);
    });
  }, []);

  const DeleteCategory = (categoryId) => {
    Axios.delete(`https://blogserver.friendlyapk.com/api/remove/${categoryId}`);
  };
   if (login) {
    return (
      <div className="DeleteForm">
        <Header />
        {categoryList.map((value) => {
          return (
            <div className="deletecard" key={value.categoryid.toString()}>
              <h1>{value.name} </h1>
              <button
                onClick={() => {
                  DeleteCategory(value.categoryid);
                }}
              >
                Delete
              </button>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <Navigate to="../" />;
  }
}

export default DeleteTech