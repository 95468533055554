//  IMPORT COMPONENTS
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// Import PAGES
import "./App.css";
import InsertForm from "./CreateBlog";
import DeleteForm from "./DeleteBlog";
import EditGame from "./EditBlog";
import CreateCategory from "./CreateCategory";
import DeleteCategory from "./DeleteCategory";
import Admin from "./admin";
// IMPORT PARTS
import ScrollToTop from "./ScrollToTop";
import { LoginContext } from "./Context";
import gamepadlogo from "./img/gamepad.png"
function App() {
  const [login, setLogin] = useState(false);

  return (
    <LoginContext.Provider value={{ login, setLogin }} className="App">
      <ScrollToTop />
        <div className="logo">
          <img src={gamepadlogo} alt="Logo" />
          <h1>FriendlyAPK BLOGSPOT</h1>
        </div>
     
      <Routes>
      <Route path="/" element={<Admin />} />
        <Route path="/createblog" element={<InsertForm />} />
        <Route path="/deleteblog" element={<DeleteForm />} />
        <Route path="/editblog" element={<EditGame />} />
        <Route path="/createcategory" element={<CreateCategory />} />
        <Route path="/deletecategory" element={<DeleteCategory />} />
      </Routes>
    </LoginContext.Provider>
  );
}

export default App;
