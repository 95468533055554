import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "./Context";
import "./App.css";
import Axios from "axios";
import Header from "./Header";

function DeleteForm() {
  const [blogList, setBlogList] = useState([]);
  const { login } = useContext(LoginContext);

  useEffect(() => {
    Axios.get("https://blogserver.friendlyapk.com/api/getblog").then((res) => {
      setBlogList(res.data);
    });
  }, []);

  const DeleteBlog = (blogId) => {
    Axios.delete(`https://blogserver.friendlyapk.com/api/delete/${blogId}`);
  };

  if (login) {
    return (
      <div className="DeleteForm">
        <Header />
        {blogList.map((value) => {
          return (
            <div className="deletecard" key={value.blogid.toString()}>
              <h1>{value.title} </h1>
              <button
                onClick={() => {
                  DeleteBlog(value.blogid);
                }}
              >
                Delete
              </button>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <Navigate to="../" />;
  }
}
export default DeleteForm;
