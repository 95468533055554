import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "./Context";
import "./App.css";
import Axios from "axios";
import Header from "./Header";

//CK EDITOR PACKS
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

function EditGame() {
  const [blogList, setBlogList] = useState([]);
  const [category, setCategory] = useState([]);
  const { login } = useContext(LoginContext);

  useEffect(() => {
    Axios.get("https://blogserver.friendlyapk.com/api/getblog").then((res) => {
      setBlogList(res.data);
    })
    Axios.get("https://blogserver.friendlyapk.com/api/getcategory").then((res) => {
      setCategory(res.data);
    });
  }, []);
  
  const [blogId, setBlogId] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogCategory, setBlogCategory] = useState("");
  const [blogTag, setBlogTag] = useState("");
  const [blogCode, setBlogCode] = useState("");
  const [blogImg, setBlogImg] = useState("");
  const token = localStorage.getItem("token");

  const updateBlog = () => {
    Axios.put("https://blogserver.friendlyapk.com/api/updateblog", {
      blogId:blogId,
      blogTitle:blogTitle,
      blogCategory:blogCategory,
      blogTag:blogTag,
      blogCode:blogCode,
      blogImg:blogImg,
      token:token,
    }).then(() => {
      alert("Successfully Inserted");
    });
  };
  if(login){
    return (
      <div className="InsertFormGame">
        <Header />
        <h2>Edit Blog Post</h2>

        <label htmlFor="list">Blog Title</label>
        <select
          name="list"
          onChange={(e) => {
            setBlogId(e.target.value);
          }}
        >
          <option value="">Select Game</option>
          {blogList.map((value) => {
            return (
              <option
                key={value.blogid.toString()}
                value={value.blogid}
                onClick={() => {
                  setBlogTitle(value.title);
                  setBlogCategory(value.category);
                  setBlogTag(value.tag);
                  setBlogCode(value.code);
                  setBlogImg(value.img);
                }}
              >
                {value.title}
              </option>
            );
          })}
        </select>

        <label htmlFor="title">Title</label>
      <input
        type="text"
        name="title"
        id=""
        placeholder={blogTitle}
        onChange={(e) => {
          setBlogTitle(e.target.value);
        }}
      />
      <p>{blogTitle}</p>

      <label htmlFor="tab">Tag List</label>
      <input
        type="text"
        name="tag"
        id=""
        onChange={(e) => {
          setBlogTag(e.target.value);
        }}
      />
      <p>{blogTag}</p>

      <label htmlFor="img">Image Url</label>
      <input
        type="url"
        name="img"
        id=""
        onChange={(e) => {
          setBlogImg(e.target.value);
        }}
      />

      <p>{blogImg}</p>

      <label htmlFor="category">Category</label>
       <select
          name="category"
          onChange={(e) => {
          }}
        >
          <option value="">Select Blog Post</option>
          {category.map((value) => {
            return (
              <option
                key={value.categoryid.toString()}
                onClick={() => {
                  setBlogCategory(value.name);
                }}
              >
                {value.name}
              </option>
            );
          })}
        </select>

          <p>{blogCategory}</p>

      <label htmlFor="code">Create Blog Post Here</label>
      <CKEditor
                    editor={ ClassicEditor }
                    data={blogCode}
                    onReady={ () => {
                        // You can store the "editor" and use when it is needed.
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setBlogCode(data);
                    } }
                    onBlur={ ( event, editor ) => {

                    } }
                    onFocus={ ( event, editor ) => {

                    } }
                />

      <p>{blogCode}</p>
      <button type="submit" onClick={updateBlog}>
        Submit
      </button>
      </div>
    )
  } 
  else {
    return <Navigate to="../" />;
  }
}
export default EditGame;
