import React, { useContext, useState } from "react";
import Axios from "axios";
import { LoginContext } from "./Context";


function Sign() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const {login, setLogin} = useContext(LoginContext);

  const SignInRequest = (e) => {
    Axios.post("https://blogserver.friendlyapk.com/api/signin", {
      username: username,
      password: password,
    }).then((res) => {
        if(!res.data.auth){
          setLogin(false);
        } else{
          setLogin(true);
          localStorage.setItem("token", res.data.token)
          setMessage(res.data.message);
        }
    }).catch((res) => {
        setMessage(res.data.message);
    })
    e.preventDefault();
    e.target.reset();

  };

  const signOut = ( )=> {
    localStorage.removeItem("token");
    localStorage.removeItem("authenticator");
    setLogin(false);
    alert("You are Signed Out Now");
  }
  

  return (
    <div className="Sign">
      <h2 className="h2">Authorisation Required!</h2>
      <form action="../" onSubmit={SignInRequest}>
        <input
          type="text"
          name="username"
          placeholder="USER ID HERE"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <input
          type="password"
          name="password"
          placeholder="PASSWORD"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <button className="btn1" type="submit">
          Sign In
        </button>
      </form>
      <button className="btn1" type="submit" onClick={signOut}>
          Sign Out
        </button>
      <h3 className="h3">{message}</h3>
      {login? <h2>Signed In</h2>: <h2>Not Sign In Yet</h2>}
    </div>
  );
}

export default Sign;
